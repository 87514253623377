<template>
  <CFooter :fixed="false" class="align-items-center">
    <div>
      {{ $t("by") }} Forvis Mazars Digital Center of Excellence &copy; {{ currentYear }}
    </div>
    <!-- <div style="margin-left: 5px; font-style: italic">
      (Testing phase, design is subject to change in June 2024)
    </div> -->
    <div class="flex-1 d-flex flex-wrap justify-content-end small" v-if="links">
      <component
        :is="link.link ? 'a' : 'span'"
        v-for="link in links"
        :key="link.text"
        target="_blank"
        :href="link.link"
        class="ml-3"
      >
        <i class="fa mr-1" :class="link.icon" />
        {{ link.text }}
      </component>
    </div>
  </CFooter>
</template>

<script>
import { FooterService } from "@/services/footer";
import { ActiveUser } from "@/services/user";

export default {
  name: "TheFooter",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  data() {
    return {
      links: null,
      isAdministrator: this.$store.state.role.includes("superuser"),
    };
  },
  async mounted() {
    if (ActiveUser.isAdministrator()) {
      this.links = await FooterService.fetchLinks();
    }
  },
};
</script>
