export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Home",
        to: "/",
        icon: "cil-home",
      },
      {
        _name: "CSidebarNavTitle",
        _children: ["DataMorph"],
      },
      {
        _name: "CSidebarNavItem",
        name: "New upload",
        to: "/new-upload",
        icon: "cil-note-add",
      },
      {
        _name: "CSidebarNavItem",
        name: "Previous uploads",
        to: "/previous-uploads",
        icon: "cil-storage",
      },
      {
        _name: "CSidebarNavItem",
        name: "Data management",
        to: "/data-management",
        icon: "cil-pencil",
      },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Anonymize",
      //   to: "/anonymize",
      //   icon: "cil-low-vision",
      // },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Connect",
      //   to: "/connect",
      //   icon: "cil-transfer",
      // },
      {
        _name: "CSidebarNavItem",
        name: "BI marketplace",
        to: "/bi-marketplace",
        icon: "cil-bar-chart",
      },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Script marketplace",
      //   to: "/script-marketplace",
      //   icon: "cil-bar-chart",
      // },
      {
        _name: "CSidebarNavTitle",
        _children: ["Administrating"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Users guide",
        to: "/user_guide",
        icon: "help_icon",
      },
      {
        _name: "CSidebarNavItem",
        name: "Users",
        to: "/users",
        icon: "cil-user",
      },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Script management",
      //   to: "/script_management",
      //   icon: "cil-settings",
      // },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Script marketplace",
      //   to: "/script_marketplace",
      //   icon: "cil-settings",
      // },
    ],
  },
];
