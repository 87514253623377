<template>
  <CHeader fixed with-subheader light>
    <CHeaderNavLink to="/" class="d-flex align-items-center">
      <!-- <img
        src="/img/mz_logo.png"
        alt=""
        style="max-height: 50px; width: 175px; object-fit: contain"
      /> -->
    </CHeaderNavLink>
    <!-- <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    /> -->

    <CHeaderNav>
      <div
        style="
          display: flex;
          justify-content: space-between;
          width: 100vw;
          align-items: center;
        "
      >
        <div :class="minimize ? 'minimize' : 'maximize'">
            <b-breadcrumb
              :items="breadcrumbItems"
              style="border: none; margin-top: 25px"
            ></b-breadcrumb>
        </div>
        <!-- <CSelect
        class="mr-3 mb-1"
        :value.sync="$i18n.locale"
        :options="languages"
        @update:value="setLanguage"
      /> -->
        <div class="d-flex gap-3 mr-5">
          <div>{{ login }}</div>
          <div class="logout" @click="logout" data-cy="logout">
            <i class="fa fa-lock" />
            <span>
              {{ $t("Logout") }}
            </span>
          </div>
        </div>
      </div>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import { freeSet } from "@coreui/icons";
import { ActiveUser } from "@/services/user";
import { LanguageMixin } from "@/mixins/language";

export default {
  name: "TheHeader",
  mixins: [LanguageMixin],
  freeSet,
  data() {
    return {
      login: ActiveUser.get()?.email.toLowerCase(),
      breadcrumbItems: [],
    };
  },
  computed: {
    show() {
      console.log('show: ', this.$store.state.sidebarShow)
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
  created() {
    console.log("");
    this.generateBreadcrumb();
  },
  methods: {
    logout() {
      ActiveUser.clear();
      localStorage.removeItem("userRole");
      this.$router.replace({ name: "Login" });
    },
    generateBreadcrumb() {
      const currentURL = window.location.pathname;
      const urlParts = currentURL.split("/").filter(Boolean);

      let breadcrumb = [];
      let href = "/";
      let lastSegmentIsReport = false;

      const transformText = (text) => {
        return text
          .replace(/-/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase());
      };

      for (const part of urlParts) {
        href += part;
        let text = part;

        if (part === "bi-marketplace") {
          text = "BI-Marketplace";
        } else if (part === "report") {
          lastSegmentIsReport = true;
          continue;
        } else if (part === "user_guide") {
          breadcrumb.push({ text: "User Guide", href });
          lastSegmentIsReport = true;
          continue;
        } else if (lastSegmentIsReport) {
          breadcrumb.push({ text: "BI Template", href });
          lastSegmentIsReport = false;
          continue;
        }

        breadcrumb.push({ text: transformText(text), href });
        href += "/";
      }

      breadcrumb.unshift({ text: "DataMorph", href: "/" });

      this.breadcrumbItems = breadcrumb;
    },
  },
  watch: {
    "$route.params": {
      handler: function (search) {
        this.generateBreadcrumb();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.minimize {
  margin-left: 30px; 
  text-decoration: none;
  transition: all 300ms ease;
}
.maximize {
  margin-left: 250px; 
  text-decoration: none;
  transition: all 300ms ease;
}
.logout {
  cursor: pointer;
  border-bottom: 1px solid transparent;

  &:hover {
    border-color: $gray-base;
  }
}
</style>
