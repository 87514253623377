<template>
  <div
    class="c-app app header-fixed sidebar-fixed sidebar-lg-show"
    :key="`locale-${$i18n.locale}`"
  >
    <ThePageHeader v-if="IS_MZ_BRANDING" />
    <TheHeader v-else />
    <TheSidebar />
    <CWrapper>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheFooter from "./TheFooter";
import ThePageHeader from "./ThePageHeader";
import TheHeader from "./TheHeader";
import { IS_MZ_BRANDING } from "@/constants/config";

export default {
  name: "TheContainer",
  data() {
    return {
      IS_MZ_BRANDING,
    };
  },
  components: {
    TheSidebar,
    TheFooter,
    ThePageHeader,
    TheHeader,
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.c-body {
  background: #f8f9fc;
}
</style>
