import moment from "moment";
import { ActiveUser } from "@/services/user";
import { locales } from "@/locales";

export const LanguageMixin = {
  computed: {
    languages() {
      return Object.keys(locales).map((locale) => ({
        label: locale.toUpperCase(),
        value: locale,
      }));
    },
  },
  mounted() {
    const language = ActiveUser.getLanguage();
    if (language) {
      this.setLanguage(language);
    }
  },
  methods: {
    async setLanguage(value) {
      await ActiveUser.setLanguage(value);
      moment.locale(value);
      this.$i18n.locale = value;
    },
  },
};
