<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <div class="logo__wrapper">
      <button
        class="minimize-btn"
        @click="$store.commit('set', ['sidebarMinimize', !minimize])"
      >
        <CIcon name="bars" size="custom" />
      </button>
      <img
        @click="$router.push('/')"
        v-if="!minimize"
        src="/img/ForvisMazars-transparent.png"
        alt=""
        style="
          max-height: 50px;
          max-width: 175px;
          object-fit: contain;
          cursor: pointer;
        "
      />
    </div>
    <CRenderFunction flat :content-to-render="navbar" />
    <!-- <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    /> -->
  </CSidebar>
</template>

<script>
import nav from "./_nav";
import { users } from "@/services/users";
import store from "../store";
import { ActiveUser } from "@/services/user";

export default {
  name: "TheSidebar",
  nav,
  data() {
    return {
      navbar: [...JSON.parse(JSON.stringify(nav))],
    };
  },
  async mounted() {
    this.sidebarRoleCheck();
    this.$forceUpdate();
  },
  computed: {
    navbar_changed() {
      this.sidebarRoleCheck();
      this.$forceUpdate();
    },
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
  methods: {
    sidebarRoleCheck() {
      const userRole = ActiveUser.getRoles();
      if (!userRole.includes("superuser")) {
        if (this.navbar[0]["_children"].length > 5) {
          this.navbar[0]["_children"] = nav[0]["_children"].slice(0, -1);
        }
      } else {
        this.navbar = nav;
      }
    },
  },
};
</script>
<style lang="scss">
.logo__wrapper {
  padding: 18px 20px;
  white-space: nowrap;
}
.minimize-btn {
  background: inherit;
  border: none;
  margin-right: 30px;
  padding: 0;
  outline: none;

  &:focus {
    outline: none;
  }

  .c-icon {
    width: 24px;
    height: 24px;
  }
}
</style>
