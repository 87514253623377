<template>
  <PageHeader
    logo-url="/img/ForvisMazars.png"
    app-name="DataMorph"
    :should-hide-brand-logo="true"
    :app-iconic-logo="''"
    :userName="userName"
    :handleLogout="logoutUser"
  />
</template>

<script>
import { freeSet } from "@coreui/icons";
import { ActiveUser } from "@/services/user";

export default {
  name: "ThePageHeader",
  freeSet,
  data() {
    return {
      isAuthenticated: ActiveUser.getToken(),
      userName: ActiveUser.get()?.email.toLowerCase(),
    };
  },
  methods: {
    logoutUser() {
      ActiveUser.clear();
      localStorage.removeItem("userRole");
      this.$router.replace({ name: "Login" });
    },
  },
};
</script>
