import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const FOOTER_ENDPOINTS = {
  links: `${BASE_URL}api/footer/links/`,
};

class Footer {
  async fetchLinks() {
    const { data } = await request({
      url: FOOTER_ENDPOINTS.links,
    });
    return data;
  }
}

export const FooterService = new Footer();
